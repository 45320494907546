<template>
  <!-- 历史记录 -->
  <div class="app-container product-list-comp">
    <div class="filter-line">
      <div class="header">
        <el-form ref="recordForm" :model="form" label-width="80px">
          <span>时间范围</span>
          <el-date-picker
            v-model="form.date"
            style="width: 240px"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            unlink-panels
          >
          </el-date-picker>
          <span style="margin-left: 10px">定位监测点</span>
          <el-cascader
            ref="myCascader"
            v-model="form.selectedOptions"
            :options="options"
            :props="defaultParams"
            @expand-change="handleChange"
            placeholder="请输入点位名称"
            style="width: 20%"
            clearable
            filterable
          ></el-cascader>
          <el-button type="primary" @click="search" style="margin-left: 10px"
            >搜索</el-button
          >
          <el-button @click="reset">重置</el-button>
          <el-button
            @click="exportExcel"
            icon="el-icon-upload2"
            style="color: #409eff"
            >导出</el-button
          >
        </el-form>
      </div>
      <main>
        <template v-if="show == true">
          <div class="serachDiv">请输入查询条件</div>
        </template>
        <template v-else>
          <el-table
            v-loading="tableLoading"
            ref="recordTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            border
          >
            <el-table-column type="index" label="序号" width="50">
            </el-table-column>
            <el-table-column property="lastUpdateTime" label="获取时间">
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.lastUpdateTime | dateFormat("YYYY-MM-DD HH:mm:ss")
                  }}
                </span>
              </template>
            </el-table-column>

            <el-table-column property="placeName" label="地点名称" width="120">
            </el-table-column>
            <el-table-column property="siteName" label="站点名称">
            </el-table-column>
            <el-table-column property="pointName" label="点位名称">
            </el-table-column>
            <el-table-column label="检测值/单位">
              <template slot-scope="scope">
                <span> {{ scope.row.value }}{{ scope.row.unit }} </span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size.sync="currentPageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </template>
      </main>
    </div>
  </div>
</template>

<script>
import {
  getHistoryPage,
  historyExport,
  getAllPlace,
  getSiteByPlaceId,
} from "@/api/ruge/vlink/wanwu/historicalRecord";
export default {
  data() {
    return {
      tableLoading: false,
      currentPage: 1,
      pageSizes: [10, 20, 50],
      currentPageSize: 10,
      total: 0,
      show: true,
      form: {
        date: [],
        selectedOptions: [],
      },
      options: [],
      defaultParams: {
        label: "fieldName",
        value: "fieldId",
        children: "fieldList",
        expandTrigger: "hover",
        // lazy: true,
      },
      tableData: [],
      currentRow: null,
      //请求数据
      queryList: {
        startTime: null,
        endTime: null,
        pointId: null,
      },
      newData: [],
      pickerOptions: {
        disabledDate: (time) => {
          const day = 180 * 24 * 3600 * 1000; // 31536000000
          // 返回小于当前日期并两个月内的日期
          return (
            time.getTime() >= Date.now() ||
            time.getTime() < Date.now() - 8.64e7 - day
          );
        },
      },
    };
  },
  mounted() {
    this.getAllPlaceDetail();
  },
  methods: {
    handleCurrentChange(curpage) {
      this.currentPage = curpage;
      this.search();
    },
    handleSizeChange(val) {
      this.currentPageSize = val;
      this.search();
    },
    //搜索
    search() {
      if (!this.form.date[0] || !this.form.date[1]) {
        this.$message.info("请输入查询时间范围");
        return;
      }
      if (!this.form.selectedOptions[2]) {
        this.$message.info("请选择具体监测点");
        return;
      }
      // 将结束时间定为当天的23:59:59
      this.form.date[1] += 1000 * 60 * 60 * 24 - 1;
      this.tableLoading = true;
      const requestParams = {
        startTime: this.form.date[0],
        endTime: this.form.date[1],
        pointId: this.form.selectedOptions[2],
        // current: (this.currentPage - 1) * this.currentPageSize + 1,
        current: this.currentPage,
        rowCount: this.currentPageSize,
      };
      getHistoryPage(requestParams)
        .then((res) => {
          this.show = false;
          this.total = res.total;
          this.tableData = res.rows;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    //重置
    reset() {
      this.show = true;
      this.form.date = [];
      this.form.selectedOptions = [];
    },
    //导出
    exportExcel() {
      if (!this.form.date[0] || !this.form.date[1]) {
        this.$message.info("请输入查询时间范围");
        return;
      }
      if (!this.form.selectedOptions[2]) {
        this.$message.info("请选择具体监测点");
        return;
      }
      historyExport({
        startTime: this.form.date[0],
        endTime: this.form.date[1],
        pointId: this.form.selectedOptions[2],
      }).then((msg) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId: msg,
          taskName: "站点列表",
          taskStatus: 0,
          rootPath: "historyPath",
        };
        //将导出任务丢入导出任务列表中
        //异步存储
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    //获取定位监测点数据
    getAllPlaceDetail() {
      //获取地点列表
      getAllPlace().then((res) => {
        this.options = res.data.map((item) => {
          return {
            fieldId: item.placeId,
            fieldName: item.placeName,
            fieldList: [],
          };
        });
      });
    },
    //获取站点列表
    getSiteByPlaceIdDetail(placeId) {
      getSiteByPlaceId({ placeId: placeId }).then((res) => {
        //把返回的数据根据placeid 赋值点击对应那条数据下的数组就可以
        let tempArray = [];
        res.data.forEach((it) => {
          let jsona = {
            fieldId: it.siteId,
            fieldName: it.siteName,
            fieldList: [],
          };
          it.siteBindDevices =
            it.siteBindDevices == null ? [] : it.siteBindDevices;
          it.siteBindDevices.forEach((devItem) => {
            devItem.siteDeviceBindPoints =
              devItem.siteDeviceBindPoints == null
                ? []
                : devItem.siteDeviceBindPoints;
            devItem.siteDeviceBindPoints.forEach((item) => {
              jsona.fieldList.push({
                fieldId: item.pointId,
                fieldName: item.pointName,
              });
            });
          });
          tempArray.push(jsona);
        });
        this.options.forEach((p) => {
          if (placeId === p.fieldId) {
            p.fieldList = tempArray;
          }
        });
      });
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    //子节点点击回调@change
    //展开回调@expand-change
    handleChange(val) {
      if (val.length > 1) {
        return;
      }
      this.getSiteByPlaceIdDetail(val[0]);
    },
  },
};
</script>
<style scoped lang="less">
.product-list-comp {
  padding: 32px 24px;
  .header {
    margin-bottom: 30px;
    span {
      margin-right: 10px;
    }
    // .header-button {
    //   position: absolute;
    //   right: 0;
    // }
  }
  main {
    .serachDiv {
      width: 100%;
      height: 50px;
      line-height: 50px;
      border: 1px solid #d7d7d7;
      text-align: center;
      color: #d7d7d7;
    }
  }
  /deep/ .el-range-separator {
    min-width: 30px;
  }
}
</style>
