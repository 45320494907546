import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';
/**历史记录查询
https://sit.gspark.cc/saascloud/saas/wanwu-data/property/historyPage
请求方式：GET
请求参数：startTime
endTime
pointId
offset
rowCount
 */
export function getHistoryPage(params) {
  return request({
    url: envInfo.bgApp.historyPath + '/historyPage',
    method: 'get',
    params,
  });
}
/**历史数据导出
https://sit.gspark.cc/saascloud/saas/wanwu-data/property/historyExport
请求方式：get
请求参数：startTime
endTime
pointId
 */
export function historyExport(params) {
  return request({
    url: envInfo.bgApp.historyPath + '/historyExport',
    method: 'post',
    data: params,
  });
}
/**小程序功能接口
 * 站点切换-地点列表
https://sit.gspark.cc/saascloud/saas/wanwu-manage/wx/getAllPlace
请求方式：GET
请求参数：无
 */
export function getAllPlace() {
  return request({
    url: envInfo.bgApp.allPlacePath + '/getAllPlace',
    method: 'get',
  });
}
/**小程序功能接口
 * 站点切换-通过地点id获取站点列表
https://sit.gspark.cc/saascloud/saas/wanwu-manage/wx/site/getSiteByPlaceId
请求方式：GET
请求参数：placeId
 */
export function getSiteByPlaceId(params) {
  return request({
    url: envInfo.bgApp.allPlacePath + '/site/findSitesByPlaceId',
    method: 'get',
    params,
  });
}
